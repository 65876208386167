import React from 'react'
import PublicCallout from '../PublicCallout'
import FooterBase from '../../../../molecules/FooterBase'

const PublicFooter = () => {
	return (
		<>
			<PublicCallout />
			<FooterBase />
		</>
	)
}

export default PublicFooter
