import React from 'react'
import { Button, Box, Image, Text, VStack, useColorModeValue } from '@chakra-ui/react'
import useImageGenerator from '../../../../../hooks/useImageGenerator'

const DISCOVER_VIDDAY_LINK = 'https://vidday.com'

const PublicCallout = () => {
	const img = useImageGenerator({
		path: '/assets/images/graphics/smiling-face-with-heart-eyes',
		ext: 'png',
	})

	const color = useColorModeValue('dark', 'light')

	return (
		<VStack spacing="1rem" my="2rem" mx="1rem">
			<Image boxSize="40px" alt="A gift they'll never expect, or forget." src={img.src} srcSet={img.srcset} />
			<Box w="full" maxW="500px" textAlign="center">
				<Text color={color} fontWeight="bold">
					A gift they’ll never expect, or forget.
				</Text>
				<Text color={color}>
					Invite friends and family to bring together the memories that matter, showcasing the people and
					moments that shaped their life.
				</Text>
			</Box>
			<Button
				as="a"
				target="_blank"
				href={DISCOVER_VIDDAY_LINK}
				color={color}
				h="2.25rem"
				variant="outline"
				colorScheme="whiteAlpha">
				Discover VidDay
			</Button>
		</VStack>
	)
}

export default PublicCallout
