import React from 'react'
import PublicHeader from '../atoms/PublicHeader'
import { Container, Heading, Text, Button, Image, VStack, Divider } from '@chakra-ui/react'
import useScrollTopOnMount from '../../../../hooks/useScrollTopOnMount'
import Card from '../../../atoms/Card'
import { useEventRecipients } from '../../../../hooks/useRecipients'
import { useEventContext } from '../../../../contexts/EventProvider'
import { useNavigate } from 'react-router-dom'
import { useEventHandlersV2 } from '../../../../hooks/useEventHandlers'
import PublicFooter from '../atoms/PublicFooter'
import useImageGenerator from '../../../../hooks/useImageGenerator'
// import PreviewControls from '../Active/molecules/PreviewControls'
import { useVideoDurationCalc } from '../../../../hooks/useVideoDurationCalc'
import { useMedia } from '../../../../hooks/useMedia'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
const PreviewControls = loadable(() => import(/* webpackPrefetch: true */ '../Active/molecules/PreviewControls'), {
	ssr: false,
})

const Publishing = () => {
	useScrollTopOnMount()
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const handlers = useEventHandlersV2(event.uuid)
	const navigate = useNavigate()

	const handleRedirect = () => navigate(`/auth/login?next=${handlers.root}`)

	const media = useMedia()
	const { durationSec } = useVideoDurationCalc(media, event.themeTemplateId)
	const refreshPageIn = durationSec > 300 ? durationSec : 300
	const cover = useImageGenerator({
		path: '/assets/images/graphics/video-rendering',
		ext: 'png',
	})

	return (
		<>
			<Helmet>
				<meta httpEquiv="refresh" content={refreshPageIn} />
			</Helmet>
			<PreviewControls />
			<PublicHeader mb="2rem" />

			<Container maxW="container.sm" pb="1rem" variant="main" px="1rem">
				<Card p="2rem" mb="1rem" textAlign="center">
					<VStack w="full">
						<Image src={cover.src} srcSet={cover.srcset} boxSize="64px" />
						<Heading variant="hero" as="h3" size="lg" color="dark" fontWeight="normal">
							This video is rendering…
						</Heading>
						<Text color="dark">
							Submissions are now closed — a big thank you to everyone who helped make this video for{' '}
							{recipients.firstname} extra special.
						</Text>
						<Text color="dark">Check back soon to see how the video turned out. 👀</Text>
					</VStack>

					<Divider borderStyle="solid" my="1rem" borderColor="gray.200" maxW="100px" mx="auto" />

					<VStack w="full">
						<Text color="dark">Are you the creator of this video?</Text>
						<Button color="link" variant="link" onClick={handleRedirect}>
							log in
						</Button>
					</VStack>
				</Card>

				{/* <Card p="2rem" textAlign="center" mb="1rem">
					<VStack>
						<Heading as="h2" variant="hero" color="dark">
							Something Good is Happening!
						</Heading>
						<Text color="dark" fontWeight="bold">
							Submissions are closed.
						</Text>
						<Text color="dark">
							Our team is hard at work creating a video for {recipients.formatted}. <br />
							Check back with {`${creator.profile.firstName} ${creator.profile.lastName}`} to see the
							final video.
						</Text>

						<Divider borderStyle="dashed" my="1rem" />

						<Text color="dark">
							If you're the creator of this video, please{' '}
							<Link color="link" onClick={handleRedirect}>
								log in
							</Link>
						</Text>
					</VStack>
				</Card> */}

				<PublicFooter />
			</Container>
		</>
	)
}

export default Publishing
